import React from "react";
import "./Footer.css";
import { FaPhoneAlt, FaMapMarkerAlt, FaEnvelope, FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Apply Here Section */}
        <div className="footer-column">
          <h3>APPLY HERE</h3>
          <ul>
            <li><a href="academicsoverview">Online Admission</a></li>
            <li><a href="/apply">How to Apply?</a></li>
            <li><a href="#">Scholarship</a></li>
          </ul>
        </div>

        {/* Learn Here Section */}
        <div className="footer-column">
          <h3>LEARN HERE</h3>
          <ul>
            <li><a href="/institute">Our Institutes</a></li>
            <li><a href="/teachingpractice">Teaching Practices</a></li>
            <li><a href="/course">Programs</a></li>
          </ul>
        </div>

        {/* Visit Here Section */}
        <div className="footer-column">
          <h3>IMPORTANT LINKS</h3>
          <ul>
            <li><a href="/about">ABOUT</a></li>
            <li><a href="/academicsoverview">ACADEMICS</a></li>
            <li><a href="/contect">CONTACT US</a></li>
            <li><a href="/course">PROGRAMS</a></li>
            <li><a href="/apply">ADMISSIONS</a></li>
            <li><a href="/alleoa">ALL EOA</a></li>
            <li><a href="/aicte">AICTE FEEDBACK FACILITY</a></li>
            <li><a href="/disclosure">MANDATORY DISCLOSURE</a></li>
            <li><a href="/grievance">ONLINE GRIEVANCE REDRESSAL</a></li>
          

          </ul>
        </div>

        {/* Get in Touch Section */}
        <div className="footer-column">
          <h3>GET IN TOUCH</h3>
          <address>
            <p>
              <FaMapMarkerAlt className="footer-icon" /> Apex Group of Institutions<br />
              National Highway No. 87, Tehsil Bilaspur<br />
              Dist. Rampur, Kaushalganj-244923, Uttar Pradesh
            </p>
            <p>
              <FaPhoneAlt className="footer-icon" /> 
              <a href="tel:+917351408009">+91-7351408009</a>
            </p>
            <p>
              <FaEnvelope className="footer-icon" /> 
              <a href="mailto:contact@apex.ac.in">contact@apex.ac.in</a>
            </p>
            <p>
              <a  href="https://www.google.com/maps/dir//Apex+Group+of+Institutions,+Kaushalganj,+Rampur,+Rudrapur+National+Highway+87,+Tehsil+Bilaspur,+Distt.+Rampur+Kaushalganj,+244923/@28.9352491,79.3496082,17z/data=!4m5!4m4!1m0!1m2!1m1!1s0x390a81ba61735f37:0x436eb9dca1074751" target="_blank" >Open in Google Maps</a>
            </p>
          </address>
        </div>
      </div>

      {/* Social Media Section */}
      <div className="footer-social">
        <a  target="_blank"  href="https://www.facebook.com/apex.ac.in"><FaFacebookF /></a>
        {/* <a href="#"><FaTwitter /></a> */}
        <a target="_blank"  href="https://www.instagram.com/apex_group_of_institutions?igsh=d3dkZmY1OWI5ZWx4"><FaInstagram /></a>
        <a target="_blank"  href="http://www.youtube.com/@apexgroupofinstitutions9354"><FaYoutube /></a>
      </div>

      <div className="footer-bottom">
  <p>
    Design & Developed By{" "}
    <a 
      href="https://www.apex.ac.in" 
      target="_blank" 
      rel="noopener noreferrer"
      className="footer-link"
    >
      an Apexian
    </a>
        {" "}
    <a 
      href="https://www.instagram.com/kishor07.ai?igsh=MTJuY3FpejAweWRpNA==" 
      target="_blank" 
      rel="noopener noreferrer"
      className="footer-icon-link"
    >
      <FaInstagram className="footer-icon" />
     
    </a>
    &{" "}

    <a 
      href="https://www.anvide.com" 
      target="_blank" 
      rel="noopener noreferrer"
      className="footer-link"
    >
      Team Anvide
    </a>
    
  </p>
  <p>
    © All rights reserved 2024
  </p>
</div>

     
    </footer>
  );
};

export default Footer;
