import React from "react";
import Lottie from "react-lottie";
import loadingAnimation from "./loading-animation.json"; 
import "./LoadingPage.css"; // Import CSS for styling

const LoadingPage = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation, // Lottie animation file
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="loading-container">
      <div className="loading-content">
        <Lottie options={defaultOptions} height={300} width={300} />
        <h2 className="loading-message">Loading, please wait...</h2>
      </div>
    </div>
  );
};

export default LoadingPage;
