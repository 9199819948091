import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import TopBar from "./Comonents/Navbar/Topbar/Topbar";
import Navbar from "./Comonents/Navbar/Navbar";
import Footer from "./Comonents/Footer/Footer";
import SocialIcons from "./Comonents/Carousel/SocialIcons";
import PrivateRoute from "./Admin/PrivateRoute/PrivateRoute";
import LoadingPage from "./Comonents/Home/Loading/LoadingPage";
import { ContactUs } from "./Comonents/Contact/ContactUs";

// Lazy-loaded components
const Home = lazy(() => import("./Comonents/Home/Home"));
const LoginRegister = lazy(() => import("./Comonents/LoginRegister/LoginRegister"));
const NoticeBoard = lazy(() => import("./Comonents/Notice/NoticeBoard/NoticeBoard"));
const NoticeUpdate = lazy(() => import("./Comonents/Notice/NoticeUpdate/NoticeUpdate"));
const Gallery = lazy(() => import("./Comonents/Gallery/Gallery"));
const UploadImages = lazy(() => import("./Comonents/Gallery/UploadImages"));
const GrievanceSection = lazy(() => import("./Comonents/GrievanceSection/GrievanceSection"));
const GrievanceUpload = lazy(() => import("./Comonents/GrievanceSection/GrievanceUpload"));
const Disclosure = lazy(() => import("./Comonents/Disclosure/Disclosure"));
const DisclosureUpload = lazy(() => import("./Comonents/Disclosure/DisclosureUpload"));
const AICTE = lazy(() => import("./Comonents/AICTE/AICTE"));
const AICTEUpload = lazy(() => import("./Comonents/AICTE/AICTEUpload"));
const AllEOA = lazy(() => import("./Comonents/AllEOA/AllEOA"));
const AllEOAUpload = lazy(() => import("./Comonents/AllEOA/AllEOAUpload"));
const ModalUpload = lazy(() => import("./Comonents/PopUp/ModalUpload"));
const Modal = lazy(() => import("./Comonents/PopUp/Modal"));

const ReceiveInquiry = lazy(() => import("./Admin/ReceiveInquiry/ReceiveInquiry"));
const NewStudentInquiry = lazy(() => import("./Admin/NewStudentInquiry/NewStudentInquiry"));
const Register = lazy(() => import("./Admin/User/Register"));
const Login = lazy(() => import("./Admin/User/Login"));
const Dashboard = lazy(() => import("./Admin/Dashboard/Dashboard"));

const AboutUs = lazy(() => import("./Comonents/About/AboutUs/AboutUs"));
const VisionMission = lazy(() => import("./Comonents/About/VisionMission/VisionMission"));
const CoreValues = lazy(() => import("./Comonents/About/CoreValues/CoreValues"));
const RecognitionsAndApprovals = lazy(() =>
  import("./Comonents/About/RecognitionsAndApprovals/RecognitionsAndApprovals")
);
const Leadership = lazy(() => import("./Comonents/About/Leadership/Leadership"));
const CoursesOffer = lazy(() => import("./Comonents/CourseCard/CoursesOffer"));
const CourseDetails = lazy(() => import("./Comonents/CourseCard/CourseDetails/CourseDetails"));
const PHARMASCIENCE = lazy(() => import("./Comonents/CourseCard/ALLCources/PHARMASCIENCE"));
const POLYTECHNIC = lazy(() => import("./Comonents/CourseCard/ALLCources/POLYTECHNIC"));
const Bed = lazy(() => import("./Comonents/CourseCard/ALLCources/Bed"));
const MANAGEMENT = lazy(() => import("./Comonents/CourseCard/ALLCources/MANAGEMENT"));
const Commerce = lazy(() => import("./Comonents/CourseCard/ALLCources/Commerce"));
const ComputerApplications = lazy(() =>
  import("./Comonents/CourseCard/ALLCources/ComputerApplications")
);
const Institute = lazy(() => import("./Comonents/Home/HComponents/Acadmics/Institute"));
const TeachingPractice = lazy(() => import("./Comonents/Home/HComponents/Acadmics/TeachingPractice"));
const AcademicsOverview = lazy(() => import("./Comonents/Home/HComponents/Acadmics/AcademicsOverview"));
const Apply = lazy(() => import("./Comonents/Home/HComponents/Apply/Apply"));
const NewStudentData = lazy(() => import("./Comonents/Home/HComponents/Apply/NewStudentData/NewStudentData"));
const ApplicationSuccess = lazy(() => import("./Comonents/Home/HComponents/Apply/ApplicationSuccess"));
const ApexEvents = lazy(() => import("./Comonents/Home/HComponents/ApexEvents.js"));
const ApexEvents1 = lazy(() => import("./Comonents/Home/HComponents/ApexEvents/ApexEvents1.js"));

const ApexEvents2 = lazy(() => import("./Comonents/Home/HComponents/ApexEvents/ApexEvents2.js"));
const ApexEvents3 = lazy(() => import("./Comonents/Home/HComponents/ApexEvents/ApexEvents3.js"));
const ApexEvents4 = lazy(() => import("./Comonents/Home/HComponents/ApexEvents/ApexEvents4.js"));
const ApexEvents5 = lazy(() => import("./Comonents/Home/HComponents/ApexEvents/ApexEvents5.js"));







function App() {
  return (
    <div className="App">
      <TopBar />
      <Navbar />
      <SocialIcons />
      <Suspense fallback={<div className="loading"><LoadingPage/></div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LoginRegister />} />
          <Route path="/notice" element={<NoticeBoard />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/grievance" element={<GrievanceSection />} />
          <Route path="/disclosure" element={<Disclosure />} />
          <Route path="/aicte" element={<AICTE />} />
          <Route path="/aicte-upload" element={<AICTEUpload />} />
          <Route path="/alleoa" element={<AllEOA />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/vision&mission" element={<VisionMission />} />
          <Route path="/corevalue" element={<CoreValues />} />
          <Route path="/recognitionsandapprovals" element={<RecognitionsAndApprovals />} />
          <Route path="/leadership" element={<Leadership />} />
          <Route path="/course" element={<CoursesOffer />} />
          <Route path="/engineering" element={<CourseDetails />} />
          <Route path="/pharma" element={<PHARMASCIENCE />} />
          <Route path="/polytechnic" element={<POLYTECHNIC />} />
          <Route path="/education" element={<Bed />} />
          <Route path="/management" element={<MANAGEMENT />} />
          <Route path="/commerce" element={<Commerce />} />
          <Route path="/bca" element={<ComputerApplications />} />
          <Route path="/institute" element={<Institute />} />
          <Route path="/teachingpractice" element={<TeachingPractice />} />
          <Route path="/academicsoverview" element={<AcademicsOverview />} />
          <Route path="/apply" element={<Apply />} />
          <Route path="/newstudentdata" element={<NewStudentData />} />
          <Route path="/application-submitted" element={<ApplicationSuccess />} />
          {/* <Route path="/register" element={<Register />} /> */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/gallery-upload"
            element={
              <PrivateRoute>
                <UploadImages />
              </PrivateRoute>
            }
          />
          <Route
            path="/updatenotice"
            element={
              <PrivateRoute>
                <NoticeUpdate />
              </PrivateRoute>
            }
          />
          <Route
            path="/grievance-upload"
            element={
              <PrivateRoute>
                <GrievanceUpload />
              </PrivateRoute>
            }
          />
          <Route
            path="/disclosure-upload"
            element={
              <PrivateRoute>
                <DisclosureUpload />
              </PrivateRoute>
            }
          />
          <Route
            path="/alleoa-upload"
            element={
              <PrivateRoute>
                <AllEOAUpload />
              </PrivateRoute>
            }
          />
          <Route
            path="/modal-upload"
            element={
              <PrivateRoute>
                <ModalUpload />
              </PrivateRoute>
            }
          />
          <Route
            path="/inquiry-contact"
            element={
              <PrivateRoute>
                <ReceiveInquiry />
              </PrivateRoute>
            }
          />
          <Route
            path="/new-student-enquiry"
            element={
              <PrivateRoute>
                <NewStudentInquiry />
              </PrivateRoute>
            }
          />
        </Routes>
      </Suspense>
      <Footer />
    </div>
  );
}

export default App;
