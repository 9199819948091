import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

  
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('navbar-menu-open');
        } else {
            document.body.classList.remove('navbar-menu-open');
        }
    }, [isOpen]);

    return (
        <nav className="navbar-component">
            <div className="navbar-container-component">
              <Link to="/">  <img src="/img/apex_logo.png" alt="Apex Logo" className="navbar-logo-component" /></Link>
                <div className="navbar-heading-component">
                    <h1 className="navbar-title-component">Apex Group of Institutions</h1>
                    <p className="navbar-subtitle-component">
                        B.TECH. | POLYTECHNIC | MBA | BBA | BCA | B.COM. (HONS.) | B.ED. | 
                        D.PHARM. | B.PHARM. | B.Sc.(PCM/Home Sc.)
                    </p>
                </div>
                <button className="menu-toggle-component" onClick={toggleMenu}>
                    <span className={isOpen ? "menu-bar-component open" : "menu-bar-component"}></span>
                    <span className={isOpen ? "menu-bar-component open" : "menu-bar-component"}></span>
                    <span className={isOpen ? "menu-bar-component open" : "menu-bar-component"}></span>
                </button>
                <ul className={`nav-links-component ${isOpen ? "open" : ""}`}>
                    <li><a href="/about" className="nav-link-component">ABOUT</a></li>
                    <li><a href="/course" className="nav-link-component">PROGRAMS</a></li>
                    <li><a href="/academicsoverview" className="nav-link-component">ACADEMICS</a></li>
                    <li><a href="/apply" className="nav-link-component">ADMISSIONS</a></li>
                    {/* <li><a href="#placements" className="nav-link-component">PLACEMENTS</a></li> */}
                    <li><a href="/aicte" className="nav-link-component">AICTE FEEDBACK FACILITY</a></li>
                    <li><a href="/gallery" className="nav-link-component">GALLERY</a></li>
                    <li><a href="/disclosure" className="nav-link-component">MANDATORY DISCLOSURE</a></li>
                    <li><a href="/grievance" className="nav-link-component">GRIEVANCE</a></li>
                    <li><a href="/contact" className="nav-link-component">CONTACT US</a></li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
