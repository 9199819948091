import React from 'react';
import { FaInstagram, FaFacebookF, FaYoutube, FaLinkedinIn, FaWhatsapp } from 'react-icons/fa';
import './SocialIcons.css';

const SocialIcons = () => {
  const socialLinks = [
    { id: 1, icon: <FaInstagram />, name: 'Instagram', link: 'https://www.instagram.com/apex_group_of_institutions?igsh=d3dkZmY1OWI5ZWx4' },
    { id: 2, icon: <FaFacebookF />, name: 'Facebook', link: 'https://www.facebook.com/apex.ac.in' },
    { id: 3, icon: <FaYoutube />, name: 'YouTube', link: 'http://www.youtube.com/@apexgroupofinstitutions9354' },
    { id: 4, icon: <FaLinkedinIn />, name: 'LinkedIn', link: 'https://www.linkedin.com/company/apex-group-of-institutions-rampur/' },
  ];

  return (
    <div>
      {/* Social Icons */}
      <div className="social-icons-container">
        {socialLinks.map((social, index) => (
          <a
            key={social.id}
            href={social.link}
            className="social-icon"
            target="_blank"
            rel="noopener noreferrer"
            style={{ top: `${40 + index * 60}px` }}
          >
            <div className="icon">{social.icon}</div>
            <span className="icon-name">{social.name}</span>
          </a>
        ))}
      </div>

      
      <a href="/apply" className="apply-now-button">
        Apply Now
      </a>

      <a href="https://wa.me/+917351408009" className="whatsapp-button">
        <FaWhatsapp className="whatsapp-icon" />
      </a>
    </div>
  );
};

export default SocialIcons;
