import React, { useEffect, useState } from "react";
import { auth, db } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserRole = async (user) => {
      try {
        console.log("Fetching user role for:", user.uid); // Debug log
        const userDoc = await getDoc(doc(db, "users", user.uid));

        if (userDoc.exists()) {
          const userData = userDoc.data();
          console.log("User document data:", userData); // Debug log

          const fetchedRole = userData?.role;
          if (fetchedRole) {
            setRole(fetchedRole);
          } else {
            console.warn("No role found in user document.");
            setRole(null); // Set role to null if missing
          }
        } else {
          console.warn("No user document found in Firestore.");
          setRole(null); // Handle case where document does not exist
        }
      } catch (error) {
        console.error("Error fetching user role:", error);
        setRole(null); // Handle Firestore read errors
      } finally {
        setLoading(false); // Ensure loading state is updated
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserRole(user);
      } else {
        console.warn("No authenticated user detected.");
        setRole(null); // No user logged in
        setLoading(false);
      }
    });

    return () => unsubscribe(); // Clean up listener on unmount
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Show loading state
  }

  if (role !== "admin") {
    console.warn("Redirecting to unauthorized. Current role:", role); // Debug log
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

export default PrivateRoute;
