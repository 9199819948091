import React, { useState, useEffect } from 'react';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase'; 
import './ContactUs.css';
import { toast, ToastContainer } from 'react-toastify'; // Make sure to import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Add this import for the styles
import { Helmet } from 'react-helmet';

export const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [quoteDetails, setQuoteDetails] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
    program: '', // New field for Program
    city: '',    // New field for City
  });

  const [uploading, setUploading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuoteDetails({ ...quoteDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);
    try {
      // Add a timestamp to the form data
      const dataWithTimestamp = {
        ...quoteDetails,
        timestamp: Timestamp.fromDate(new Date()), // Add Firestore-compatible timestamp
      };

      const docRef = await addDoc(collection(db, 'quotes'), dataWithTimestamp);
      console.log('Document written with ID: ', docRef.id);

      // Reset form after successful submission
      setQuoteDetails({
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
        program: '',
        city: '',
      });

      toast.success('Message Submitted Successfully!'); // This will show the success toast
    } catch (error) {
      console.error('Error adding document: ', error);
      toast.error('Something went wrong. Please try again.'); // This will show the error toast
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className='contact-page'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us</title>
        <meta name="description" content="We're Always Eager to Hear From You!" />
      </Helmet>

      <div className='contact-content'>
        <div className='contact-details'>
          <h2>We're Always Eager to <br /> Hear From You!</h2>
          <b>Address</b>
          <p>National Highway 87, Tehsil Bilaspur, Distt. Rampur, Kaushalganj, 244923</p>
          <b>Email</b>
          <p>Info@apex.ac.in</p>
          <b>Phone</b>
          <p>+917351408009</p>
        </div>

        <div className='contact-form'>
          <h2>Get In Touch</h2>
          <p>Fill out this form for booking a consultant advising session.</p>
          <form onSubmit={handleSubmit}>
            <label>Name:</label>
            <input
              className='contact-input'
              type='text'
              name='name'
              value={quoteDetails.name}
              onChange={handleChange}
              required
            />
            <label>Email:</label>
            <input
              className='contact-input'
              type='email'
              name='email'
              value={quoteDetails.email}
              onChange={handleChange}
              required
            />
            <label>Phone Number:</label>
            <input
              className='contact-input'
              type='text'
              name='phoneNumber'
              value={quoteDetails.phoneNumber}
              onChange={handleChange}
              required
            />
            <label>Select Program:</label>
            <select
              className='contact-input'
              name='program'
              value={quoteDetails.program}
              onChange={handleChange}
              required
            >
              <option value="">-- Select Program --</option>
              <option value="Engineering">Engineering</option>
              <option value="Management (BBA/MBA)">Management (BBA/MBA)</option>
              <option value="Computer Applications">Computer Applications</option>
              <option value="Commerce">Commerce</option>
              <option value="Education">Education</option>
              <option value="Pharma Science">Pharma Science</option>
              <option value="Polytechnic">Polytechnic</option>
            </select>
            <label>City:</label>
            <input
              className='contact-input'
              type='text'
              name='city'
              value={quoteDetails.city}
              onChange={handleChange}
              required
            />
            <label>Message:</label>
            <textarea
              className='contact-input'
              name='message'
              value={quoteDetails.message}
              onChange={handleChange}
              required
            ></textarea>
            <button className='contact-button' type='submit'>
              {uploading ? 'Submitting...' : 'Submit Message'}
            </button>
          </form>
        </div>
      </div>

      <div className='contact-map'>
        <iframe
          width="100%"
          height="650px"
          loading="lazy"
          allowFullScreen
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3491.7574887899814!2d79.34960819999999!3d28.935249100000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390a81ba61735f37%3A0x436eb9dca1074751!2sApex%20Group%20of%20Institutions%2C%20Kaushalganj%2C%20Rampur%2C%20Rudrapur!5e0!3m2!1sen!2sin!4v1735739520000!5m2!1sen!2sin"
          className='contact-map-iframe'
        ></iframe>
      </div>

      <ToastContainer /> {/* Add ToastContainer here to show toasts */}
    </div>
  );
};
